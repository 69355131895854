h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

body {
	overflow-x: hidden;
}

img {
    max-width: 100%;
    max-height: 100%;
}

a {
    text-decoration: none;
}

ul {
    
    list-style: none;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}