.modal {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba($color: #000000, $alpha: .5);
	z-index: 100;
}

.modal.is-open {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-wrapper {
	width: 100%;
	max-width: 560px;
	height: 100%;
	max-height: 672px;
	background: #ffffff;
	padding: 72px 70px 100px;
	position: relative;
	.close-modal {
		position: absolute;
		top: 16px;
		right: 16px;
	}
	h2 {
		font-family: 'Inter';
		font-weight: 500;
		font-size: 32px;
		line-height: 40px;
		text-align: center;
		color: #111111;
		margin-bottom: 36px;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		input, textarea {
			width: 100%;
			font-family: 'Inter';
			font-size: 16px;
			line-height: 24px;
			color: #626262;
			padding: 16px 20px;
			border: 1px solid #CCCCCC;
			outline: none;
		}
		input {
			margin-bottom: 16px;
		}
		textarea {
			min-height: 132px;
			margin-bottom: 24px;
			resize: none;
		}
		a {
			width: 100%;
			margin-bottom: 12px;
			padding: 20px;
		}
		span {
			font-family: 'Inter';
			font-size: 15px;
			line-height: 24px;
			text-align: center;
			color: #333333;
		}
	}
	@media (max-width: 768px) {
		width: 95%;
		padding: 72px 12px 100px;
	}
}